import { Component, OnInit } from '@angular/core';
import { filter, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { NpsStore } from '../../../services/nps.store';
import { UserMeDto } from '../../shared/api/user-details.model';
import { UserDetailsService } from '../../shared/api/user-details.service';

@Component({
    selector: 'nps-menu',
    templateUrl: './nps.menu.component.html'
})
export class NpsMenuComponent implements OnInit {

  items: any[] = [];
  isLoading = false;

  allItems = [
    //{
    //  label: 'Home',
    //  icon: 'pi pi-fw pi-home',
    //  routerLink: '/'
    //},
    {
      label: 'Projects',
      icon: 'pi pi-fw pi-cog',
      permission: 'ProjectGetList,UserProjectMappingGetList',
      items: [{
        label: 'Projects Profiles',
        icon: 'pi pi-fw pi-cog',
        routerLink: '/nps/projects/list',
        permission: 'ProjectGetList'
      },
      {
        label: 'User Project Mappings',
        icon: 'pi pi-fw pi-users',
        routerLink: '/nps/projects/users',
        permission: 'UserProjectMappingGetList'
      }
      ]
    },
    {
      label: 'Networks',
      icon: 'pi pi-fw pi-sitemap',
      permission: 'OpNetworkRequestGetList,GpNetworkRequestCreate',
      items: [{
        label: 'Network Requests',
        icon: 'pi pi-fw pi-sitemap',
        routerLink: '/nps/network-requests/list',
        permission: 'OpNetworkRequestGetList'
      },
      {
        label: 'New Network',
        icon: 'pi pi-fw pi-plus',
        routerLink: '/nps/network-requests/new',
        permission: 'GpNetworkRequestCreate'
      }
      ]
    },
    {
      label: 'Project Spokes',
      permission: 'GpProjectSpokesGetList',
      items: [
        // {
        //   //label: '[comming soon] Hubs Topology (categories)',
        //   label: '[comming soon] Hubs Topology',
        //   icon: 'pi pi-fw pi-sitemap',
        //   routerLink: '/nps/project-spokes/hubs/topology',
        //   permission: 'OpProjectSpokesTopology'
        // },
        {
          label: 'Primary Hubs',
          icon: 'pi pi-fw pi-server',
          routerLink: '/nps/project-spokes/hubs/primary-hubs/list',
          permission: 'OpPrimaryHubsGetList'
        },
        {
          label: 'Secondary Hubs',
          icon: 'pi pi-fw pi-server',
          routerLink: '/nps/project-spokes/hubs/secondary-hubs/list',
          permission: 'OpSecondaryHubsGetList'
        },
        {
          label: 'Project Spokes',
          icon: 'pi pi-fw pi-asterisk',
          routerLink: '/nps/project-spokes/list',
          permission: 'GpProjectSpokesGetList'
        },
        {
          label: 'Spoke Requests (admin)',
          icon: 'pi pi-fw pi-asterisk',
          routerLink: '/nps/project-spokes/requests/list',
          permission: 'OpSpokeRequestsGetList'
        },
        {
          label: 'Replicate Existing Spoke',
          icon: 'pi pi-fw pi-plus',
          routerLink: '/nps/project-spokes/replicate',
          permission: 'OpSpokeCreateReplication'
        },
      ]
    },
    {
      label: 'Firewall',
      permission: 'GpFirewallRequestCreate',
      items: [
        {
          label: 'Firewall Rules',
          icon: 'pi pi-fw pi-shield',
          routerLink: '/nps/firewall/rules/list',
          permission: 'OpFirewallRulesGetList,GpFirewallProjectRulesGetList'
        },
        {
          label: 'Firewall Requests',
          icon: 'pi pi-fw pi-shield',
          routerLink: '/nps/firewall/requests/list',
          permission: 'OpFirewallRequestsGetList,GpFirewallRequestsGetList'
        },
        {
          label: 'Create Firewall Request',
          icon: 'pi pi-fw pi-plus',
          routerLink: '/nps/firewall/requests/create',
          permission: 'GpFirewallRequestCreate'
        },
      ]
    }
  ];

  constructor(
    private userService: UserDetailsService,
    private store: NpsStore
  ) {
    this.isLoading = true;
  }

  ngOnInit() {
    this.store.npsUser$
    .pipe(
      filter((user): user is UserMeDto => user != null),
      switchMap((user : UserMeDto) => {
        const itemProcessObservables$: Observable<any | null>[] = this.allItems.map(item => {
          const hasItemPermission$ = item.permission
            ? this.userService.hasPermission(item.permission, user.roles)
            : of(true);

          return hasItemPermission$.pipe(
            switchMap(hasItemPerm => {
              if (!hasItemPerm) {
                return of(null);
              }

              const subItemPermissionChecks$: Observable<boolean>[] = item.items.map(subItem =>
                subItem.permission
                  ? this.userService.hasPermission(subItem.permission, user.roles)
                  : of(true)
              );

              if (subItemPermissionChecks$.length === 0) {
                return of({ ...item, items: [...item.items] });
              }

              return forkJoin(subItemPermissionChecks$).pipe(
                map(subPermissions => {
                  const filteredSubItems = item.items.filter((_, index) => subPermissions[index]);
                  return { ...item, items: filteredSubItems };
                })
              );
            })
          );
        });

        return forkJoin(itemProcessObservables$).pipe(
          map(results => results.filter(processedItem => processedItem !== null) as any[])
        );
      })
    )
    .subscribe({
      next: filteredItems => {
        this.items = filteredItems;
        this.isLoading = false;
      },
      error: (e) => {
        console.error('[nps.menu.component]\n' + e);
        this.isLoading = false;
      },
      complete: () => { this.isLoading = false;}
    });
  }
}
